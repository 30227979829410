import React from "react"

const PG_R_FEEDBACK_CONTENT = {
  Trustpilot: {
    secondString: "Please rate us on Trustpilot!",
    textButton: "Yelp a review on Trustpilot",
    label: "TRUSTPILOT",
    link: "https://www.trustpilot.com/review/amzscout.net",
  },
  // Facebook: {
  //   secondString: "Please rate us on Facebook!",
  //   textButton: "Yelp a review on facebook",
  //   label: "FACEBOOK",
  //   link: "",
  // },
  Chrome_Store: {
    secondString: "Please rate us in Chrome Store!",
    textButton: "Yelp a review in Chrome Store",
    label: "CHROME_STORE",
    link: "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea",
  },
}
export default PG_R_FEEDBACK_CONTENT
